<template>
  <div class="allPage">
    <heads :more="showMore" :msg="title_msg"></heads>
    <van-list
      v-if="isShow"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(thread,index) in list" :key="index" @click="go_url(thread)">
        <datalists
          :type="1"
          :listType="2"
          :thumb="thread.pic"
          :atitle="thread.title"
          :createTime="thread.create_time"
          :vNum="thread.view_num"
          :cNum="thread.reply_num"
        ></datalists>
      </div>
    </van-list>
    <div v-else class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt />
      <p>您还没有上传过哦~</p>
    </div>
  </div>
</template>


<script>
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import { get_uplist } from '@/api/my_user.js'
import datalists from '@/compontens/datalist'
import tool from '@/utils/tools.js'

export default {
  components: {
    heads,
    commontop,
    datalists
  },
  data () {
    return {
      loading: false,
      finished: false,
      title_msg: '我的上传',
      limit: 10,
      page: 1,
      list: [],
      isShow: true,
      retlen: 0,
      total: 0,
      showMore: true
    }
  },
  created () {
    // window.onpageshow = this.showAgin
  },
  beforeMount () {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function (e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  methods: {
    showAgin () {

      // this.$toast('showAgin')
      // this.page = 1
      // this.list = []
      // this.retlen = 0
      // this.total = 0
      // this.finished = false
      // this.onLoad()
    },
    go_url (item) {
      const url = item.url
      window.location.href = url
    },
    clicks () {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    onLoad () {
      this.loading = true
      var params = {
        limit: this.limit,
        page: this.page,
      }
      get_uplist(params).then((res) => {
        console.log(res)
        this.loading = false
        const result = res.result ? res.result : {}
        const count = result.count ? result.count : 0
        const list = result.list ? result.list : []
        if (this.page === 1) {
          this.list = list
        }
        else {
          this.list = [...this.list, ...list]
        }
        this.total = count
        console.log(this.list)
        console.log(this.total)
        // 是否有数据
        if (this.list.length > 0) {
          this.isShow = true
          // 是否有更多
          if (this.list.length < this.total) {
            this.page++
            this.finished = false
          } else {
            this.finished = true
          }
        } else {
          this.isShow = false
        }

        console.log('page is ' + this.page)
      })
    },
  }
}
</script>


<style lang="scss" scoped>
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}

// .thread {
//   width: 100%;
//   height: 280px;
//   display: flex;
//   justify-content: flex-end;
//   border-bottom: #eee 1px solid;
// }
// .content {
//   // width: calc(100% - 280px);
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   //自动计算剩余宽度
//   flex: 1;
//   .thread_title {
//     margin: 30px 20px 30px 30px;
//     font-size: 30px;
//     color: #333333;
//     line-height: 50px;
//     word-break: break-all;
//     word-wrap: break-word;
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 3;
//     -webkit-box-orient: vertical;
//   }
//   .bot_info {
//     height: 50px;
//     margin-bottom: 20px;
//     line-height: 50px;
//     .eye_img {
//       margin-left: 30px;
//       width: 28px;
//       height: 18px;
//     }
//     .people_bro {
//       margin-left: 8px;
//       color: #999999;
//       display: inline-block;
//     }
//     .kuang_img {
//       margin-left: 30px;
//       width: 24px;
//       height: 18px;
//     }
//     .people_gen {
//       margin-left: 8px;
//       color: #999999;
//       display: inline-block;
//     }
//   }
// }
// .right_box {
//   width: 280px;
//   height: 250px;
//   margin-top: 30px;
//   margin-right: 30px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   .thumb {
//     width: 100%;
//     height: 158px;
//   }
//   .bot_time {
//     height: 50px;
//     margin-bottom: 20px;
//     text-align: right;
//     line-height: 50px;
//     color: #999999;
//   }
// }
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
